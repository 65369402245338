import { GetStaticPropsResult } from 'next'

import { SanitySiteFragment } from '@data/sanity/queries/types/site'
import { SanityLoginPage } from '@data/sanity/queries/types/page'
import { getPreview } from '@lib/sanity/client'
import { getLoginPage } from '@lib/sanity/page'
import { Locale } from '@lib/language'
import { CustomStaticPropsContext } from '@lib/routes'

import Modules from '@modules/modules'
import Layout from '@components/layout'

interface LoginPageProps {
  locale: Locale
  page: SanityLoginPage
  site: SanitySiteFragment
}

const LoginPage = ({ page, site }: LoginPageProps) => (
  <Layout page={page} site={site}>
    <Modules modules={page.modules} cartSettings={site.cart} />
  </Layout>
)

export async function getStaticProps({
  locale,
  preview,
  previewData,
}: CustomStaticPropsContext): Promise<GetStaticPropsResult<LoginPageProps>> {
  const loginPage = await getLoginPage(locale, getPreview(preview, previewData))

  if (!loginPage.page) {
    return { notFound: true }
  }

  return {
    props: {
      locale,
      page: loginPage.page,
      site: loginPage.site,
    },
    revalidate: 60,
  }
}

export default LoginPage
